<script>
import Vue from "vue";
import footerLayout from "../../layouts/footerLayout";
import headerLayout from "../../layouts/headerLayout";
import ClassOnScroll from "vue-class-on-scroll";
//     import carousel from 'vue-owl-carousel'

import { DropdownPlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)

import { TooltipPlugin } from 'bootstrap-vue'
Vue.use(TooltipPlugin)

import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon,
} from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";

import { IntegerPlusminus } from "vue-integer-plusminus";

import "bootstrap-vue/dist/bootstrap-vue.css";

import DatePicker from "vue2-datepicker";

import AxiosPlugin from "vue-axios-cors";

Vue.use(AxiosPlugin);

// import VueDropdown from 'vue-dynamic-dropdown'

// 	  can register the component globally By adding this in main.js

// Vue.component('vue-dropdown', VueDropdown);

export default {
  data() {
    return {
      orderQrImage: "",
      orderTypeByEnum: "", //1=> pickup, 2=> delivery
      orderStatus: "",
      orderPlacedOn: "",
      PaymentType: "",
      orderedItemDetails: undefined,
      cartDetails: undefined,
      pickUpon: "",
      isCafeteria: 1,
      autoClose: "",
      picked: "",
      orderObj: "",
      restaurantDetails: "",
      allReviewsData: [],
      terms: false,
      nextStep: true,
      isHidden: true,
      activeTab: "option0",
      deliver: false,
      terms: false,
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
        "white",
      ],
      // headerBgVariant: 'dark',
      // headerTextVariant: 'light',
      // bodyBgVariant: 'light',
      // bodyTextVariant: 'dark',
      footerBgVariant: "white",
      footerTextVariant: "dark",
      // userID: JSON.parse(localStorage.getItem('userData')).userID,
      userName: "",
      // userEmail: JSON.parse(localStorage.getItem('userData')).emailID,
      // userMobile: JSON.parse(localStorage.getItem('userData')).mobileNo,
      orderInfo: null,
      pageType: "",
    };
  },
  directives: {
    "class-on-scroll": ClassOnScroll,
  },
  computed: {
    isDisabled: function () {
      return !this.terms;
    },
  },
  mounted() {
    this.axios
      .get("/getUserDeatils")
      .then((response) => {
        // console.log(response);
        let status = response.status;
        if (status == 200 && response.data.status == 200) {
          if (response.data.userData.userName) {
            this.userName = response.data.userData.userName;
          } else {
            this.userName = "";
          }
        }
        // this.cartStatus = response.data.cartdetails;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    //if (this.$route.params.pageType == 'success') {
    //  this.$analytics.logEvent('purchase_orders', { 
    //      userID: localStorage.getItem('userID'), 
    //      userName: localStorage.getItem('userName'), 
    //      orderID: this.$route.query.id,
    //  }, { 'debug_mode':true })
    // }

    if (this.$route.params.pageType) {
      this.pageType = this.$route.params.pageType;
    }
    var OID = this.$route.query.id;
    // var orderDetailsbyID = this.$route.query.od; // 1=> with orderID, 0=>transactionID
    var uri = this.$route.query.id.split("?");
    var isOrderByID = "";
    var orderDetailsID = "";
    if (uri.length == 2) {
      var orderDetailsID = uri[0];
      var isOrderByIDArr = uri[1].split("=");
      if (isOrderByIDArr.length == 2) {
        var isOrderByID = isOrderByIDArr[1]; // 1=> with orderID, 2=>transactionID
      }
    }
    // console.log(uri);
    // console.log(orderDetailsID);
    // console.log(isOrderByIDArr[1]);
    if (orderDetailsID != "" && isOrderByID != "") {
      let orderIDData = JSON.stringify({
        orderDetailsID: orderDetailsID,
        isOrderByID: isOrderByID,
      });
      Vue.axios.post("/orderDetails", orderIDData).then((res) => {
        //   console.log(res);
        let status = res.status;
        if (status == 200) {
          this.orderInfo = res.data.data.orderID;
          this.orderQrImage = res.data.data.orderQrImage;
          this.orderObj = res.data.data;
          let getOrderStatus = res.data.data.orderStatus;
          switch (getOrderStatus) {
            case 1:
              this.orderStatus = "initialized";
              break;
            case 2:
              this.orderStatus = "Placed";
              break;
            case 3:
              this.orderStatus = "Accepted";
              break;
            case 4:
              this.orderStatus = "Preparing";
              break;
            case 5:
              this.orderStatus = "Prepared";
              break;
            case 6:
              this.orderStatus = "Dispatched";
              break;
            case 7:
              this.orderStatus = "Delivered";
              break;
            case 8:
              this.orderStatus = "Cancelled";
              break;
            default:
              this.orderStatus = "Placed";
          }
          let orderData = res.data.data;
          if (
            orderData.enumOrderMethod == "pickup" ||
            orderData.enumOrderMethod == "dinenow" ||
            orderData.enumOrderMethod == "schedule_pickup" ||
            orderData.enumOrderMethod == "subscription_pickup"
          ) {
            this.orderTypeByEnum = 1; //1=> pickup, 2=> delivery
          } else if (
            orderData.enumOrderMethod == "deliver_now" ||
            orderData.enumOrderMethod == "schedule_delivery" ||
            orderData.enumOrderMethod == "subscription_delivery"
          ) {
            this.orderTypeByEnum = 2; //1=> pickup, 2=> delivery
          }
          // let getPaymentType = res.data.data.PaymentType;
          // switch(getPaymentType){
          // 	case 1:
          // 	this.PaymentType = "Online";
          // 	break;
          // 	case 2:
          // 	this.PaymentType = "Sodexo";
          // 	break;
          // 	case 3:
          // 	this.PaymentType = "Cash On Delivery";
          // 	break;
          // default:
          // 	this.PaymentType = "Cash On Delivery";
          // }
          // this.PaymentType = res.data.data.paymentMethodName;
          if (
            res.data.data.paymentMethodName != "" &&
            res.data.data.PaymentModeName != ""
          ) {
            this.PaymentType =
              res.data.data.PaymentModeName +
              " + " +
              res.data.data.paymentMethodName;
          } else if (
            res.data.data.paymentMethodName == "" &&
            res.data.data.PaymentModeName != ""
          ) {
            this.PaymentType = res.data.data.PaymentModeName;
          } else if (
            res.data.data.paymentMethodName != "" &&
            res.data.data.PaymentModeName == ""
          ) {
            this.PaymentType = res.data.data.paymentMethodName;
          }
          this.orderPlacedOn = res.data.data.placedOn;
          this.pickUpon = res.data.data.pickupDateTime;
          this.restaurantDetails = res.data.restaurantDetails;
          this.orderedItemDetails = res.data.orderedItemsDetails;
          this.cartDetails = res.data.totalOrderDetails;
          // console.log(this.cartDetails);
        }
      });
    }

    this.getReviewByOrderID(orderDetailsID);
  },
  components: {
    headerLayout,
    footerLayout,
    //  carousel,
    //  VueDropdown,
    DatePicker,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
    IntegerPlusminus,
  },
  methods: {
    getReviewByOrderID(orderID) {
      var orderID = orderID;
      this.axios
        .post("getFdbackReviewByOrderID", {
          orderID: orderID,
        })
        .then((response) => {
          this.averageRating = response.data.Avg;
          this.allReviewsData = response.data.data;
        });
    },
  },
};
</script>

<template>
  <div class="container-fuild m-0 p-0">
    <headerLayout />
    <!-- header Layout -->

    <main>
      <div class="bg_gray">
        <div class="container margin_detail">
          <div class="row">
            <div class="col-lg-8 list_menu">
              <div id="reviews">
                <div class="review_card">
                  <div class="row">
                    <div class="col-md-6 review_content">
                      <div class="clearfix">
                        <h3 v-if="isCafeteria === 0" class="cardTitle">
                          Delivery Address
                        </h3>
                        <h3 v-if="isCafeteria === 1" class="cardTitle">
                          Hello {{ userName }}
                        </h3>
                        <p
                          class="orderStatus"
                          style="color: #f3766a !important"
                        >
                          Your Order is {{ orderStatus }}
                        </p>
                      </div>

                      <!-- Order type Pick up -->
                      <div class="orderInfo" v-if="orderTypeByEnum == 1">
                        <p>
                          Please show your
                          <a class="customAnch">Order #{{ orderInfo }}</a> at
                          the counter, to pickup your order.
                        </p>
                        <span style="color: #777777"
                          >Pick your order from,</span
                        >
                        <div
                          class="strip"
                          style="margin-top: 9px"
                          v-if="restaurantDetails != ''"
                        >
                          <a
                            class="menu_item restaurantCard"
                            style="background: #fef1f0"
                          >
                            <figure>
                              <img
                                :src="
                                  restaurantDetails.restaurantImage == null ||
                                  restaurantDetails.restaurantImage == ''
                                    ? 'img'
                                    : restaurantDetails.restaurantImage
                                "
                                onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';"
                                width="90"
                                data-src="img/menu-thumb-1.jpg"
                                alt="thumb"
                                class="lazy"
                              />
                            </figure>
                            <div>
                              <h3 style="font-size: 17px">
                                {{ restaurantDetails.restaurantName }} -
                                {{ restaurantDetails.restaurantBranchName }}
                              </h3>
                              <span style="font-size: 14px; color: #777">{{
                                restaurantDetails.restaurantAddress
                              }}</span>
                            </div>
                          </a>
                        </div>
                        <!-- pickup from div -->

                        <div
                          class="paymentTypeFromData"
                          style="margin-bottom: 12px"
                        >
                          <div class="row">
                            <div class="col-4">
                              <img
                                src="../../templateAssests/img/ckgallery/packaging.png"
                                alt="thumb"
                                width="70"
                                height="70"
                              />
                            </div>
                            <div class="col-8 align-right">
                              <p>Pickup</p>
                              <p>Pickup order, {{ pickUpon }}</p>
                            </div>
                          </div>
                        </div>

                        <!-- Payment type div -->
                        <div class="paymentTypeFromData">
                          <div class="row">
                            <div class="col-4">Payment Type</div>
                            <div
                              class="col-8 align-right"
                              style="font-family: 'GothamMedium' !important"
                            >
                              {{ PaymentType }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- / Order type Pick up -->

                      <!--  Order type Delivery  -->
                      <div class="orderInfo" v-if="orderTypeByEnum == 2">
                        <div
                          class="strip"
                          style="margin-top: 9px"
                          v-if="restaurantDetails != ''"
                        >
                          <a
                            class="menu_item deliverCard restaurantCard"
                            style="background: #fef1f0; min-height: 100px"
                          >
                            <figure>
                              <img
                                :src="
                                  restaurantDetails.restaurantImage == null ||
                                  restaurantDetails.restaurantImage == ''
                                    ? 'img'
                                    : restaurantDetails.restaurantImage
                                "
                                onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';"
                                width="90"
                                data-src="img/menu-thumb-1.jpg"
                                alt="thumb"
                                class="lazy"
                              />
                            </figure>
                            <div class="deliveryCardDiv">
                              <h3 class="deliverH3" style="font-size: 17px">
                                {{ restaurantDetails.restaurantName }}
                              </h3>
                              <span style="font-size: 14px; color: #777">{{
                                restaurantDetails.restaurantBranchName
                              }}</span>
                            </div>
                          </a>
                        </div>
                        <!-- delivery from div -->
                        <div class="deliverToCard">
                          <span style="color: #777777">Deliver to,</span>
                          <div
                            class="deliverToCardDiv"
                            style="margin-bottom: 12px"
                          >
                            <div class="row" v-if="orderObj != ''">
                              <div class="col-md-12 col-lg-12 col-xl-12">
                                <p class="addressTypeDelivery">
                                  {{ orderObj.addressType }}
                                </p>
                              </div>
                              <div class="col-md-12 col-lg-12 col-xl-12">
                                <p class="addressDelivery">
                                  {{ orderObj.address }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="paymentTypeFromData deliveryPaymentType"
                          style="margin-bottom: 12px"
                        >
                          <div class="row">
                            <div class="col-4">
                              <img
                                src="../../templateAssests/img/ckgallery/Delivery.png"
                                alt="thumb"
                                width="70"
                                height="70"
                              />
                            </div>
                            <div class="col-8 align-right">
                              <p>Delivery</p>
                              <p style="margin-bottom: 0px">
                                Estimated Delivery,
                              </p>
                              <p>{{ pickUpon }}</p>
                            </div>
                          </div>
                        </div>

                        <!-- Payment type div -->
                        <div class="paymentTypeFromData">
                          <div class="row">
                            <div class="col-4">Payment Type</div>
                            <div
                              class="col-8 align-right"
                              style="font-family: 'GothamMedium' !important"
                            >
                              {{ PaymentType }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- / Order type Delivery -->
                    </div>
                    <div class="col-md-6 review_content align-right">
                      <div
                        class="clearfix"
                        v-if="orderQrImage == null || orderQrImage == ''"
                      >
                        <span class="orderNo"
                          >Order #<strong>{{ orderInfo }}</strong></span
                        >
                        <p class="orderplacedOn">
                          Placed on: {{ orderPlacedOn }}
                        </p>
                      </div>
                      <div class="clearfix row" v-else>
                        <div class="col-9" style="padding-right: 0px">
                          <span class="orderNo"
                            >Order #<strong>{{ orderInfo }}</strong></span
                          >
                          <p class="orderplacedOn">
                            Placed on: {{ orderPlacedOn }}
                          </p>
                        </div>
                        <div class="col-3" style="margin-bottom: 9px">
                          <img
                            v-if="orderQrImage != null || orderQrImage != ''"
                            :src="
                              orderQrImage == null || orderQrImage == ''
                                ? 'img'
                                : orderQrImage
                            "
                            width="80"
                            height="80"
                            style="object-fit: contain"
                            alt="thumb"
                            class="lazy"
                          />
                        </div>
                      </div>
                      <div
                        class="cartItemsOrderDetails align-left"
                        v-if="cartDetails.length > 0"
                      >
                        <h4 style="font-family: 'GothamMedium' !important">
                          Items <small>({{ cartDetails[0].itemCount }})</small>
                        </h4>
                        <div
                          class="row cartTotalItemDetails"
                          v-for="items in orderedItemDetails"
                          v-bind:key="items"
                          style="margin-top: 2px; margin-bottom: 7px"
                        >
                          <div class="col-8 dishName">
                            <h5>
                              <span>{{ items.quantity }}x </span
                              >{{ items.dishTitle }}
                              <div
                                class="foodTypeContent align-center"
                                :style="{ background: foodType.background }"
                                v-for="foodType in items.foodType"
                                v-bind:key="foodType.name"
                                :id="foodType.name"
                                v-b-tooltip.hover="foodType.name"
                                :title="foodType.name"
                              ></div>
                            </h5>
                            <div
                              class="orderedAddons"
                              v-if="
                                items.orderedItemAddon.length != '' &&
                                items.orderedItemAddon.length != null
                              "
                            >
                              <p
                                v-for="addOns in items.orderedItemAddon"
                                v-bind:key="addOns"
                              >
                                + {{ addOns.addonTitle }}
                              </p>
                            </div>
                            <!-- <div class="dishServings">Serve {{items.servings}}</div>
                                    <div class="foodType align-center" :style="{background : foodType.background}" v-for="foodType in items.foodType" v-bind:key="foodType.name">
                                        {{foodType.shortCode}}
                                      </div> -->
                          </div>
                          <!-- / col -->
                          <div class="col-4 dishDetails align-right">
                            <div class="dishRate align-right">
                              ₹{{ items.itemPrice }}
                            </div>
                            <div
                              class="orderedAddons dishRate align-right"
                              v-if="
                                items.orderedItemAddon.length != '' &&
                                items.orderedItemAddon.length != null
                              "
                            >
                              <p
                                v-for="addOns in items.orderedItemAddon"
                                v-bind:key="addOns"
                              >
                                ₹{{ addOns.addonPrice }}
                              </p>
                            </div>
                          </div>
                          <!-- / col -->
                          <!-- <hr class="hrBorder"> -->
                        </div>
                        <hr class="hrBorder" />
                        <div class="row chargesDiv" style="margin-top: 12px">
                          <div
                            class="col-8"
                            v-if="cartDetails[0].totalCorporateSubsidy > 0"
                          >
                            Corporate Subsidy
                          </div>
                          <!-- / col -->
                          <div
                            class="col-4 align-right"
                            v-if="cartDetails[0].totalCorporateSubsidy > 0"
                          >
                            - ₹{{ cartDetails[0].totalCorporateSubsidy }}
                          </div>
                          <!-- / col -->

                          <div class="col-8">Item Total</div>
                          <!-- / col -->
                          <div class="col-4 align-right">
                            ₹{{ cartDetails[0].itemTotal }}
                          </div>
                          <!-- / col -->

                          <div
                            class="col-8"
                            style="color: #a4827d"
                            v-if="
                              cartDetails[0].couponCode != '' &&
                              cartDetails[0].couponCode != null &&
                              cartDetails[0].couponDiscount > 0
                            "
                          >
                            Discount ({{ cartDetails[0].couponCode }})
                          </div>
                          <!-- / col -->
                          <div
                            style="color: #a4827d"
                            class="col-4 align-right"
                            v-if="
                              cartDetails[0].couponCode != '' &&
                              cartDetails[0].couponCode != null &&
                              cartDetails[0].couponDiscount > 0
                            "
                          >
                            - ₹{{ cartDetails[0].couponDiscount }}
                          </div>
                          <!-- / col -->

                          <div
                            class="col-8"
                            style="color: #a4827d"
                            v-if="
                              cartDetails[0].voucherIdentifier != '' &&
                              cartDetails[0].voucherIdentifier != null &&
                              cartDetails[0].voucherType == 2 &&
                              cartDetails[0].voucherDiscount > 0
                            "
                          >
                            Voucher Redeemption (#{{
                              cartDetails[0].voucherIdentifier
                            }})
                          </div>
                          <!-- / col -->
                          <div
                            style="color: #a4827d"
                            class="col-4 align-right"
                            v-if="
                              cartDetails[0].voucherIdentifier != '' &&
                              cartDetails[0].voucherIdentifier != null &&
                              cartDetails[0].voucherType == 2 &&
                              cartDetails[0].voucherDiscount > 0
                            "
                          >
                            - ₹{{ cartDetails[0].voucherDiscount }}
                          </div>
                          <!-- / col -->

                          <div class="col-8">Taxes & Charges *</div>
                          <!-- / col -->
                          <div class="col-4 align-right">
                            ₹{{ cartDetails[0].tax }}
                          </div>
                          <!-- / col -->

                          <div
                            class="col-8"
                            style="color: #a4827d"
                            v-if="
                              cartDetails[0].voucherIdentifier != '' &&
                              cartDetails[0].voucherIdentifier != null &&
                              cartDetails[0].voucherType == 1 &&
                              cartDetails[0].voucherDiscount > 0
                            "
                          >
                            Voucher Redeemption (#{{
                              cartDetails[0].voucherIdentifier
                            }})
                          </div>
                          <!-- / col -->
                          <div
                            style="color: #a4827d"
                            class="col-4 align-right"
                            v-if="
                              cartDetails[0].voucherIdentifier != '' &&
                              cartDetails[0].voucherIdentifier != null &&
                              cartDetails[0].voucherType == 1 &&
                              cartDetails[0].voucherDiscount > 0
                            "
                          >
                            - ₹{{ cartDetails[0].voucherDiscount }}
                          </div>
                          <!-- / col -->

                          <div
                            class="col-8"
                            v-if="
                              cartDetails[0].walletDiscount != '' &&
                              cartDetails[0].walletDiscount != null &&
                              cartDetails[0].walletDiscount > 0
                            "
                          >
                            Wallet Redeemption ({{
                              cartDetails[0].walletDiscount
                            }}
                            Pts)
                          </div>
                          <!-- / col -->
                          <div
                            class="col-4 align-right"
                            v-if="
                              cartDetails[0].walletDiscount != '' &&
                              cartDetails[0].walletDiscount != null &&
                              cartDetails[0].walletDiscount > 0
                            "
                          >
                            - ₹{{ cartDetails[0].walletDiscount }}
                          </div>
                          <!-- / col -->

                          <hr class="hrBorder" />
                        </div>
                        <div
                          class="row totalchargesDiv"
                          style="margin-top: 12px"
                        >
                          <div class="col-8">Total</div>
                          <!-- / col -->
                          <div class="col-4 align-right">
                            ₹{{ cartDetails[0].orderTotal }}
                          </div>
                          <!-- / col -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /row -->
                </div>
                <!-- /review_card -->
              </div>
              <!-- /reviews -->
              <div class="row cardEndDiv">
                <div class="col-6">
                  <a
                    v-if="orderStatus == 'Delivered'"
                    target="_blank"
                    :href="$ApiUrl + '/restaurant/invoice/0/' + this.orderObj.oid"
                    ><button
                      class="btn_1 view_details"
                      style="
                        color: #000 !important;
                        background: #ffc60c !important;
                      "
                    >
                      Download Invoice
                    </button></a
                  >
                  <!-- <a href="#">Cancel Order</a> -->
                </div>
                <div class="col-6 align-right">
                  <a href="/"
                    ><button
                      class="btn_1 view_details"
                      style="
                        color: #000 !important;
                        background: #ffc60c !important;
                      "
                    >
                      Home
                    </button></a
                  >
                </div>
              </div>
            </div>
            <!-- /col -->
            <div
              class="col-lg-4 orderStatusCol" id="sidebar_fixed"
            >
              <div class="orderStatusSection" v-if="pageType != 'viewDetails'" style="padding-bottom: 10px;">
                <img
                  src="../../templateAssests/img/ckgallery/orderplacedDiv.png"
                  width="360"
                />
                <div class="orderStatusDiv">
                  <img
                    src="../../templateAssests/img/ckgallery/tick.png"
                    style="margin-right: 25px"
                  />
                  Order Placed Successfully
                </div>
              </div>
              <!-- <div class="advertiseSection">
                      <img src="../../templateAssests/img/ckgallery/adv.png" height="360" width="360">
                    </div> -->
              <!-- /box_order -->
              <div
                class="reviews moblieReview"
                v-for="allReviews in allReviewsData"
                :key="allReviews.FeedbackId"
              >
                <section class="ordersSection">
                  <div class="row">
                    <div class="menu_item">
                      <div class="row">
                        <p class="reviewUserName">{{ allReviews.userName }}</p>
                        <p class="reviewDate">{{ allReviews.date }}</p>
                        <div class="col-12" v-if="allReviews.rating > 0">
                          <span v-for="index in 10" :key="index">
                            <i
                              v-if="index <= allReviews.rating"
                              class="fa fa-star"
                              style="margin-right: 5px; color: #ffc60c"
                            ></i>
                            <i
                              v-else
                              class="fa fa-star"
                              style="margin-right: 5px; color: #dadada"
                            ></i>
                          </span>
                        </div>
                        <p class="reviewStarRating">
                          {{ allReviews.rating }} of 10
                        </p>
                      </div>
                      <div class="row">
                        <p class="reviewUserText">{{ allReviews.review }}</p>
                      </div>
                      <div v-if="allReviews.allReplies != ''">
                        <hr style="margin-top: 10px; margin-bottom: 10px" />
                        <div
                          class="reviewReplyBox"
                          v-for="allReplies in allReviews.allReplies"
                          :key="allReplies.FeedbackId"
                        >
                          <div class="row">
                            <div class="col-6 text-left">
                              <p class="reviewReplyFrom">Reply from,</p>
                              <p
                                class="reviewRestName"
                                v-if="allReviews.restName != null"
                              >
                                {{ allReviews.restName }} -
                                {{ allReviews.branchName }}
                              </p>
                            </div>
                            <div class="col-6 text-right">
                              <p class="reviewReplyDate">
                                {{ allReplies.repliedDate }}
                              </p>
                            </div>
                          </div>
                          <p class="reviewRestText">
                            {{ allReplies.replyText }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
        <!-- /container -->
      </div>
      <!-- /bg_gray -->

      <!-- /container -->
    </main>
    <!-- /main -->

    <!-- Modal item order -->
    <b-modal
      id="modal-addAddress"
      modal-class="addDeliveryModal"
      :footer-bg-variant="footerBgVariant"
      hide-header
      size="md"
      centered
    >
      <div class="container deliveryAddContainer">
        <div class="clearfix addDeliveryHeader">
          <h4>Add Delivery Address</h4>
        </div>
        <!-- / clearfix -->

        <div class="deliveryLocat">
          <label>Enter your delivery location*</label>
          <select class="form-select">
            <option value="All">
              Bandra Kurla Complex, G4-A, Ground Floor,Trade Center,
              Bandra(west)
            </option>
            <option value="Veg">Veg</option>
            <option value="Non Veg">Non Veg</option>
          </select>
        </div>
        <!-- / deliveryLocat -->

        <div class="completeAdd InfoText">
          <label>Complete Address*</label>
          <input
            class="form-control"
            type="text"
            placeholder="Flat No, Building, Company, Area."
          />
        </div>
        <!-- / completeAdd -->

        <div class="landmark InfoText">
          <label>Landmark (Optional)</label>
          <input
            class="form-control"
            type="text"
            placeholder="e.g. near Apollo Hospital"
          />
        </div>
        <!-- / completeAdd -->

        <div class="addTypeRadio InfoText">
          <label>Address Type*</label>
          <div class="row">
            <div class="col-3">
              <label class="container_radio"
                >Home
                <input type="radio" value="option1" name="opt_order" checked />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-3">
              <label class="container_radio"
                >Work
                <input type="radio" value="option1" name="opt_order" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-3">
              <label class="container_radio"
                >Other
                <input type="radio" value="option1" name="opt_order" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <!-- / addTypeRadio -->
      </div>
      <!-- /Container -->
      <template #modal-footer>
        <div class="w-100">
          <b-button
            size="sm"
            class="float-right btnSave btn btn-warning"
            @click="show = false"
          >
            Save
          </b-button>
          <!-- / footer Btn end -->
        </div>
      </template>
    </b-modal>
    <!-- /Modal item order -->

    <footerLayout />
    <!-- footer layout -->
  </div>
  <!-- /root div -->
</template>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style scoped>
a.deliverCard {
  max-height: 100px !important;
  padding: 12px 20px 12px 20px !important;
}
a.deliverCard figure {
  float: left !important;
  margin-right: 11px !important;
}
.deliveryCardDiv {
  margin-top: 10px;
}
.deliverH3 {
  margin-bottom: 12px !important;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.customAnch {
  color: #f3766a !important;
}
/* .vsa-list {
      --vsa-heading-padding: 0 0;
      --vsa-highlight-color: transparent; 
      --vsa-bg-color: transparent;
      --vsa-border-color: transparent;
      --vsa-default-icon-size: 0.5;
    }
    .vsa-item__trigger__content{
      font-size:16px;
    } */
.addDeliveryModal > .modal-dialog > .modal-content > .modal-body {
  padding: 30px !important;
}
.review_card {
  padding: 25px 25px 25px 25px;
}
.deliveryCard {
  border: 1px solid #ededed;
  border-radius: 3px;
  padding: 20px;
}
.addressType h5 {
  font-size: 16px;
}
.address span {
  font-size: 14px;
}
.deliverBtn {
  margin-top: 17px;
}
.btn_1 {
  background: #a4827d;
}
.disabledBtn {
  background: rgba(52, 58, 64, 0.25) !important;
  cursor: not-allowed;
}
.btnNewAdd {
  background: #f3766a;
}
.btnSave {
  width: 85px;
  height: 35px;
}
.paymentTypeDiv {
  margin-top: 20px;
}
.otherAdd {
  margin-top: 20px;
}
.head {
  position: relative;
  top: 25px;
  border-radius: 0 !important;
  background-color: #fef1f0 !important;
  color: #000 !important;
  text-align: left !important;
  padding: 17px 10px 12px 20px !important;
}
.locationDetails p {
  margin-bottom: 0;
  margin-top: 1rem;
  color: #a6a1a1;
}
.mainCart {
  margin-top: 10px;
}
.dishServings img {
  margin-right: 5px;
}
/* .dishDetails{
      border-bottom:1px solid #ededed;
    } */
.couponsSection {
  border: 1px solid #ededed;
  padding: 14px 15px 14px;
}
.mdi-ticket-percent-outline {
  font-size: 20px;
}
.couponText {
  font-size: 15px;
  margin-left: 5px;
  margin-left: 15px;
}
.walletSection {
  border: 1px solid #ededed;
  padding: 14px 15px 14px;
}
.mdi-wallet-outline {
  font-size: 20px;
}
.walletText {
  font-size: 15px;
  margin-left: 5px;
}
/* .walletSection a{
      position: relative;
        left: 115px;
    } */
.opt_order {
  border-bottom: transparent;
  margin-bottom: 0px;
}
.dishName h5 {
  font-size: 14px;
  margin-bottom: 2px;
}

.cardTitle {
  font-size: 20px;
}
.orderStatus {
  font-size: 16px;
}
.orderNo {
  color: #777777;
  font-size: 14px;
}
.orderplacedOn {
  color: #777777;
  font-size: 14px;
}
#reviews {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05) !important;
}
.expandHead {
  color: #000;
  font-size: 13px !important;
}
.expandBody {
  margin-left: 31px;
  color: #626262;
  font-size: 12px;
}
.deliveryAddContainer {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.addDeliveryHeader {
  margin-top: 31px;
  margin-bottom: 27px;
}
.InfoText label {
  color: #777;
  font-size: 14px;
  margin: 0px 0px 10px;
}
.deliveryLocat select,
.completeAdd input,
.landmark input {
  margin-bottom: 21px;
}
.addTypeRadio label {
  margin: 0px 0px 19px;
}
.header_in {
  position: fixed;
}
main {
  margin-top: 68px;
}
.titleHead {
  margin-bottom: 21.5px;
  margin-top: 8px;
}
.optionHeading {
  margin-top: 19.5px;
  color: #212121;
  font-size: 15px;
}
.optionContentText {
  color: #424242;
  font-size: 13px;
}
.hrBorder {
  width: 96%;
  margin-top: 11px;
}
a.restaurantCard figure {
  width: 70px;
  height: 70px;
  overflow: hidden;
  /* position: absolute; */
  margin: 0;
  /* left: 20px;
      top: 12px; */
  border: 1px solid #fff;
  border-radius: 3px;
}
.cartItemsOrderDetails {
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 16px 20px 16px;
}
.chargesDiv div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.totalchargesDiv {
  font-size: 17px;
  font-family: "GothamMedium" !important;
}
.paymentTypeFromData {
  padding: 12px 20px 12px 20px;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 5px;
}
.deliverToCardDiv {
  padding: 12px 20px 12px 20px;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 5px;
}
.orderStatusDiv {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  top: 15px;
  left: 40px;
}

.addressTypeDelivery {
  font-size: 16px;
  color: #222222;
}
.addressDelivery {
  color: #777;
  font-size: 14px;
}

.cardEndDiv a {
  color: #f3766a;
  font-size: 16px;
}
.orderedAddons p {
  font-size: 12px;
  margin-bottom: 0px;
}
@media (max-width: 971px) {
  /* .orderStatusCol {
      display: none;
    } */
  .paymentTypeFromData {
    margin-bottom: 12px;
  }
}
@media (min-width: 1200px) {
  .deliveryAddContainer {
    max-width: 95% !important;
  }
}
@media only screen and (max-width: 991px) {
  .moblieReview {
    padding: 10px 10px !important;
  }
}
.menu_item {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: block;
  position: relative;
  padding: 15px;
}
.reviewUserName {
  font-weight: 600;
  font-size: 14px;
  color: #663333;
  margin-bottom: 8px;
}
.reviewDate {
  font-weight: 300;
  font-size: 13px;
  color: #979797;
  margin-bottom: 8px;
}
.reviewStarRating {
  font-weight: 300;
  font-size: 9px;
  color: #663333;
  margin-top: 2px;
  margin-bottom: 8px;
}
.reviewUserText {
  font-weight: 300;
  font-size: 14px;
  color: #663333;
  margin-bottom: 0px;
  line-height: 17px;
  word-break: break-word;
}
.reviewReplyBox {
  border-left: 5px solid #f3766a;
  padding-left: 10px;
}
.reviewReplyFrom {
  font-weight: 300;
  font-size: 11px;
  color: #979797;
  margin-bottom: 8px;
}
.reviewReplyDate {
  font-weight: 300;
  font-size: 13px;
  color: #979797;
  margin-top: 10px;
  margin-bottom: 8px;
}
.reviewRestName {
  font-weight: 600;
  font-size: 14px;
  color: #663333;
  margin-bottom: 8px;
}
.reviewRestText {
  font-weight: 300;
  font-size: 14px;
  color: #663333;
  margin-bottom: 0px;
  word-break: break-word;
}
</style>